import { BaseKey } from '@refinedev/core';

import httpClient from '../../../utils/axios';

export const buildPackage = async ({
  id,
  onFinish,
  onError
}: {
  id: BaseKey;
  onFinish?: () => void;
  onError?: (e) => void;
}) => {
  try {
    await httpClient.post(`/v2/flex_packages/${id}/create_package_file`);
  } catch (e) {
    onError?.(e);
  } finally {
    onFinish?.();
  }
};
