import { Box, Divider, Typography } from '@mui/material';
import { FieldProps } from '@rjsf/core';

import { IF } from '../../IF';

export const SectionField = ({ schema }: FieldProps) => {
  const { title, description } = schema;

  return (
    <>
      <IF condition={!!title}>
        <Box mb={1} mt={1}>
          <Typography variant="h5">{title}</Typography>
          <Divider />
        </Box>
      </IF>

      <IF condition={!!description}>
        <Typography variant="subtitle2" style={{ marginTop: '5px' }}>
          {description}
        </Typography>
      </IF>
    </>
  );
};
