import { darken, lighten } from '@material-ui/core/styles';

const warningMain = '#FF9800';
export const WARNING = {
  main: warningMain,
  dark: '#C77700',
  light: '#FFB547',
  darkText: darken(warningMain, 0.6),
  lightBg: lighten(warningMain, 0.9),
  contrastText: 'rgba(0,0,0,87)'
};

const errorMain = '#F44336';
export const ERROR = {
  main: errorMain,
  dark: '#E31B0C',
  light: '#F88078',
  darkText: darken(errorMain, 0.6),
  lightBg: lighten(errorMain, 0.9),
  contrastText: '#FFFFFF'
};

const successMain = '#4CAF50';
export const SUCCESS = {
  main: successMain,
  dark: '#3B873E',
  light: '#7BC67E',
  darkText: darken(successMain, 0.6),
  lightBg: lighten(successMain, 0.9),
  contrastText: '#FFFFFF'
};

const infoMain = '#2196F3';
export const INFO = {
  main: infoMain,
  dark: '#0B79D0',
  light: '#64B6F7',
  darkText: darken(infoMain, 0.6),
  lightBg: lighten(infoMain, 0.9),
  contrastText: '#FFFFFF'
};

const charcoalMain = '#3C4151';
export const CHARCOAL = {
  '50': '#ECECEE',
  '100': '#C5C6CB',
  '200': '#9EA0A8',
  '300': '#777A85',
  '400': '#505462',
  '500': charcoalMain,
  '600': '#363B49',
  '700': '#2A2E39',
  '800': '#1E2129',
  '900': '#121318',
  MAIN: charcoalMain
};

const firebrickMain = '#BB2726';
export const FIREBRICK = {
  '50': '#F8E9E9',
  '100': '#EBBEBE',
  '200': '#DD9393',
  '300': '#CF6867',
  '400': '#C23D3C',
  '500': firebrickMain,
  '600': '#961F1E',
  '700': '#701717',
  '800': '#4B100F',
  '900': '#250808',
  MAIN: firebrickMain
};

const vividSkyBlueMain = '#56CCF2';
export const VIVID_SKY_BLUE = {
  '50': '#EEFAFE',
  '100': '#CCF0FB',
  '200': '#ABE6F9',
  '300': '#89DBF6',
  '400': '#67D1F3',
  '500': vividSkyBlueMain,
  '600': '#4DB8DA',
  '700': '#3C8FA9',
  '800': '#2B6679',
  '900': '#1A3D49',
  MAIN: vividSkyBlueMain
};

const azureMain = '#2F80ED';
export const AZURE = {
  '50': '#EAF2FD',
  '100': '#C1D9FA',
  '200': '#97C0F6',
  '300': '#6DA6F2',
  '400': '#448DEF',
  '500': azureMain,
  '600': '#2A73D5',
  '700': '#215AA6',
  '800': '#184077',
  '900': '#0E2647',
  MAIN: azureMain
};

const goGreenMain = '#27AE60';
export const GO_GREEN = {
  '50': '#E9F7EF',
  '100': '#BEE7CF',
  '200': '#93D7B0',
  '300': '#68C690',
  '400': '#3DB670',
  '500': goGreenMain,
  '600': '#239D56',
  '700': '#1B7A43',
  '800': '#145730',
  '900': '#0C341D',
  MAIN: goGreenMain
};

const crayolaMain = '#F2C94C';
export const CRAYOLA = {
  '50': '#FEFAED',
  '100': '#FBEFC9',
  '200': '#F9E4A6',
  '300': '#F6D982',
  '400': '#F3CE5E',
  '500': crayolaMain,
  '600': '#DAB544',
  '700': '#A98D35',
  '800': '#796526',
  '900': '#493C17',
  MAIN: crayolaMain
};

const sandyBrownMain = '#F2994A';
export const SANDY_BROWN = {
  '50': '#FEF5ED',
  '100': '#FBE0C9',
  '200': '#F9CCA5',
  '300': '#F6B880',
  '400': '#F3A35C',
  '500': sandyBrownMain,
  '600': '#DA8A43',
  '700': '#A96B34',
  '800': '#794D25',
  '900': '#492E16',
  MAIN: sandyBrownMain
};

const redSalsaMain = '#EB5757';
export const RED_SALSA = {
  '50': '#FDEEEE',
  '100': '#F9CDCD',
  '200': '#F5ABAB',
  '300': '#F18989',
  '400': '#ED6868',
  '500': redSalsaMain,
  '600': '#D44E4E',
  '700': '#A53D3D',
  '800': '#762C2C',
  '900': '#461A1A',
  MAIN: redSalsaMain
};

const amethystMain = '#9B51E0';
export const AMETHYST = {
  '50': '#F5EEFC',
  '100': '#E1CBF6',
  '200': '#CDA8F0',
  '300': '#B985E9',
  '400': '#A562E3',
  '500': amethystMain,
  '600': '#8C49CA',
  '700': '#6D399D',
  '800': '#4E2970',
  '900': '#2E1843',
  MAIN: amethystMain
};

const attackPurpleMain = '#6442D3';
export const ATTACK_PURPLE = {
  '50': '#EEE8FA',
  '100': '#D2C7F1',
  '200': '#B5A2E9',
  '300': '#967BE1',
  '400': '#7D5EDA',
  '500': attackPurpleMain,
  '600': '#593DCC',
  '700': '#4935C3',
  '800': '#3A2FBC',
  '900': '#1823B0',
  lightBg: '#E8E2FA',
  darkText: darken(attackPurpleMain, 0.6)
};

const greyMain = '#9E9E9E';
export const GREY = {
  '0': '#FFFFFF',
  '50': '#FAFAFA',
  '100': '#F5F5F5',
  '200': '#EEEEEE',
  '300': '#E0E0E0',
  '400': '#BDBDBD',
  '500': greyMain,
  '600': '#757575',
  '700': '#616161',
  '800': '#424242',
  '900': '#212121'
};
