import { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { TableComponents, TableExtension } from '@remirror/extension-react-tables';
import {
  OnChangeHTML,
  EditorComponent,
  Remirror,
  ThemeProvider,
  useRemirror,
  useRemirrorContext,
  useHelpers
} from '@remirror/react';
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CalloutExtension,
  CodeBlockExtension,
  CodeExtension,
  EmojiExtension,
  HardBreakExtension,
  HeadingExtension,
  ImageExtension,
  ItalicExtension,
  LinkExtension,
  MarkdownExtension,
  OrderedListExtension,
  StrikeExtension,
  SubExtension,
  SupExtension,
  TextColorExtension,
  TrailingNodeExtension,
  UnderlineExtension
} from 'remirror/extensions';

import { themeV5 } from '../AiThemeProvider/AiThemeProvider';

import Toolbar from './components/Toolbar';
import hooks from './hooks';

import 'remirror/styles/all.css';

import './AiRemirror.scss';

const AiRemirror = ({ value, onChange }) => {
  const extensions = useCallback(
    () => [
      new BlockquoteExtension(),
      new BoldExtension(),
      new BoldExtension(),
      new BulletListExtension({ enableSpine: true }),
      new CalloutExtension(),
      new CodeBlockExtension(),
      new CodeExtension(),
      new EmojiExtension(),
      new HardBreakExtension(),
      new HeadingExtension(),
      new HeadingExtension(),
      new ImageExtension({ enableResizing: true }),
      new ItalicExtension(),
      new ItalicExtension(),
      new LinkExtension({ autoLink: true }),
      new MarkdownExtension({ copyAsMarkdown: false }),
      new OrderedListExtension(),
      new StrikeExtension(),
      new StrikeExtension(),
      new SubExtension(),
      new SupExtension(),
      new TableExtension(),
      new TextColorExtension(),
      new TrailingNodeExtension(),
      new UnderlineExtension()
    ],
    []
  );

  const { manager } = useRemirror({
    extensions,
    stringHandler: 'markdown'
  });

  return (
    <Box className="remirror-theme" sx={{ maxWidth: '1600px' }}>
      <ThemeProvider theme={AiRemirrorTheme}>
        <Remirror manager={manager} hooks={hooks}>
          <Toolbar />
          <TableComponents enableTableDeleteRowColumnButton enableTableCellMenu />
          <EditorComponent />
          <ReplaceValueOnMount value={value} />
          <OnChangeMarkdown onChange={onChange} />
        </Remirror>
      </ThemeProvider>
    </Box>
  );
};

const OnChangeMarkdown = ({ onChange }) => {
  const helpers = useHelpers();

  return (
    <OnChangeHTML
      onChange={() => {
        const markdown = helpers.getMarkdown();

        onChange(markdown);
      }}
    />
  );
};

/**
 * This is a hack to render tables properly.
 */
const ReplaceValueOnMount = ({ value }) => {
  const { setContent } = useRemirrorContext({
    autoUpdate: true
  });

  useEffect(() => {
    setContent(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const AiRemirrorTheme = {
  color: {
    text: themeV5.palette.primary.main
  }
};

export default AiRemirror;
