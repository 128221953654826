import React from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useAutocomplete } from '@refinedev/mui';
import { Control, Controller, FieldValues } from 'react-hook-form';

interface Props {
  control: Control<FieldValues, Record<PropertyKey, unknown>>;
  name: string;
}

const VendorProductSelector: React.FC<Props> = props => {
  const { autocompleteProps } = useAutocomplete({
    resource: 'vendor_products',
    fetchSize: 10000,

    onSearch: (query: string) => {
      return [
        {
          field: 'name',
          operator: 'eq',
          value: query
        }
      ];
    },

    sorters: [
      {
        field: 'name',
        order: 'asc'
      }
    ]
  });

  return (
    <Controller
      {...props}
      defaultValue={null}
      render={({ field }) => (
        <Autocomplete
          {...autocompleteProps}
          {...field}
          onChange={(_, value) => {
            field.onChange(value?.id || null);
          }}
          getOptionLabel={item => {
            return item.name
              ? item.name
              : autocompleteProps?.options.find(p => p.id.toString() === item.toString())?.name ?? '';
          }}
          renderOption={(props, option) => {
            return (
              <Box {...props} component="li" key={option.id}>
                {option.name}
              </Box>
            );
          }}
          isOptionEqualToValue={(option, value) => {
            return value === undefined || option?.id?.toString() === value?.toString();
          }}
          renderInput={params => <TextField {...params} label="Vendor Product" margin="normal" variant="outlined" />}
        />
      )}
    />
  );
};

export default VendorProductSelector;
