import { FormLabel } from '@mui/material';
import { WidgetProps } from '@rjsf/core';
import { Widgets } from '@rjsf/material-ui';

export const CheckboxWidget = (props: WidgetProps) => {
  const { schema } = props;

  return (
    <>
      <Widgets.CheckboxWidget {...props} />
      {schema.description && <FormLabel>{schema.description}</FormLabel>}
    </>
  );
};
