import React, { useState } from 'react';
import { Box, Drawer, Tab, Tabs, Typography } from '@mui/material';
import { useOne } from '@refinedev/core';

import { AiReactJsonSchemaForm } from '@attackiq/components';
import { ReactAsf } from '@attackiq/react-asf';

import TabPanel from '../../../components/mui/TabPanel/TabPanel';

type Props = {
  drawer: {
    visible: boolean;
    close: () => void;
    show: () => void;
  };
  selectedConnector: string | undefined;
};

enum DetailsTab {
  JSON,
  FORM
}

const ConnectorDetailsDrawer: React.FC<Props> = ({ drawer: { visible, close }, selectedConnector }) => {
  const [tab, setTab] = useState<DetailsTab>(DetailsTab.JSON);
  const { data: connector, isLoading } = useOne({
    resource: 'connectors',
    id: selectedConnector as string,
    queryOptions: {
      enabled: selectedConnector !== undefined
    }
  });
  const descriptorJson = connector?.data.descriptor_json || {};
  const isReactJsonSchema = 'schema' in descriptorJson || 'uiSchema' in descriptorJson;

  const handleTabChange = (_event: React.SyntheticEvent, value: DetailsTab) => {
    setTab(value);
  };

  return (
    <Drawer
      open={visible}
      anchor="right"
      onClose={close}
      PaperProps={{
        sx: {
          width: '40vw'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        {isLoading ? (
          'Loading...'
        ) : (
          <>
            <Box sx={{ py: 2 }}>
              <Typography variant="h6">{connector?.data.name} Details</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Tabs value={tab} onChange={handleTabChange} aria-label="connector detail tab">
                <Tab label="JSON" />
                <Tab label="Form" />
              </Tabs>

              <TabPanel value={tab} index={DetailsTab.JSON}>
                <Box
                  component="pre"
                  sx={{
                    p: 2,
                    overflow: 'auto',
                    maxHeight: '60vh',
                    border: '1px solid #e0e0e0',
                    backgroundColor: '#fafafa'
                  }}
                >
                  {JSON.stringify(descriptorJson, null, 2)}
                </Box>
              </TabPanel>

              <TabPanel value={tab} index={DetailsTab.FORM}>
                {isReactJsonSchema ? (
                  <AiReactJsonSchemaForm
                    id="integration-configuration-form"
                    schema={descriptorJson.schema}
                    uiSchema={descriptorJson.uiSchema}
                  />
                ) : (
                  <ReactAsf schema={descriptorJson.config} form={descriptorJson.form || ['*']} model={{}} />
                )}
              </TabPanel>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default ConnectorDetailsDrawer;
