import { ATTACK_PURPLE, CHARCOAL, ERROR, INFO, SUCCESS, WARNING } from './colors';

export const DEFAULT_THEME = {
  palette: {
    primary: {
      main: CHARCOAL['500']
    },
    secondary: {
      main: ATTACK_PURPLE['500'],
      dark: ATTACK_PURPLE['700'],
      light: ATTACK_PURPLE['200'],
      darkText: ATTACK_PURPLE['darkText'],
      lightBg: ATTACK_PURPLE['lightBg']
    },
    warning: {
      main: WARNING.main,
      dark: WARNING.dark,
      light: WARNING.light,
      darkText: WARNING.darkText,
      lightBg: WARNING.lightBg,
      contrastText: WARNING.contrastText
    },
    error: {
      main: ERROR.main,
      dark: ERROR.dark,
      light: ERROR.light,
      darkText: ERROR.darkText,
      lightBg: ERROR.lightBg,
      contrastText: ERROR.contrastText
    },
    success: {
      main: SUCCESS.main,
      dark: SUCCESS.dark,
      light: SUCCESS.light,
      darkText: SUCCESS.darkText,
      lightBg: SUCCESS.lightBg,
      contrastText: SUCCESS.contrastText
    },
    info: {
      main: INFO.main,
      dark: INFO.dark,
      light: INFO.light,
      darkText: INFO.darkText,
      lightBg: INFO.lightBg,
      contrastText: INFO.contrastText
    }
  },
  overrides: {
    MuiList: {
      root: {
        '& a:hover, & a:focus': {
          color: 'initial'
        }
      }
    },
    MuiDrawer: {
      paper: {
        maxWidth: '100vw'
      }
    },
    PrivateNotchedOutline: {
      legend: {
        border: 0
      }
    },
    MuiFormControlLabel: {
      labelPlacementTop: {
        alignItems: 'flex-start'
      }
    },
    MuiChip: {
      colorPrimary: {
        border: `1px solid ${CHARCOAL['500']}`
      },
      colorSecondary: {
        border: `1px solid ${ATTACK_PURPLE['500']}`
      },
      label: {
        textOverflow: 'unset'
      }
    },
    MuiDialogActions: {
      root: {
        padding: '8px 24px'
      }
    },
    MuiInputBase: {
      input: {
        '&:focus': {
          outline: '0 !important'
        }
      }
    }
  }
};
