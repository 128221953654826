import { Box, Divider as MuiDivider } from '@mui/material';

import {
  ColorButtonMenu,
  HeadingButtonMenu,
  ImageButton,
  MoreFormattingButtonMenu,
  TableButton,
  ToggleBoldButton,
  ToggleBulletListButton,
  ToggleCalloutButton,
  ToggleCodeSnippetButton,
  ToggleItalicButton,
  ToggleOrderedListButton
} from './buttons';

const Toolbar = () => {
  return (
    <Box sx={styles.rootCss}>
      <HeadingButtonMenu />
      <Divider />
      <ToggleBoldButton />
      <ToggleItalicButton />
      <MoreFormattingButtonMenu />
      <Divider />
      <ColorButtonMenu />
      <Divider />
      <ToggleBulletListButton />
      <ToggleOrderedListButton />
      <Divider />

      <ImageButton />
      <TableButton />
      <ToggleCodeSnippetButton />
      <ToggleCalloutButton />
    </Box>
  );
};

const Divider = () => {
  return <MuiDivider sx={{ mx: 1, height: 24 }} orientation="vertical" />;
};

export default Toolbar;

const styles = {
  rootCss: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 6
  }
};
