import { useState } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Autocomplete, Box, IconButton, TextField, Typography } from '@mui/material';
import { useCreate, useDelete, useList, useOne, useResource } from '@refinedev/core';

import { ATTACKIQ_ID } from '@attackiq/constants';

const PhaseMitigations = () => {
  const { id: phaseId } = useResource();
  const [mitigationSearchValue, setMitigationSearchValue] = useState('');
  const { mutateAsync: createAsync } = useCreate();
  const { mutateAsync: deleteAsync } = useDelete();

  // because /v1/phase_mitigations doesnt allow to filter by phase_id we get the mitigations from the phase object but using a different query so when invalidated it's invalidated locally
  const { data: phaseMitigations, refetch: refetchPhaseMitigations } = useOne({
    resource: 'phases',
    id: phaseId as string,
    meta: {
      queryId: 'phase_mitigations'
    },
    queryOptions: {
      select: data => {
        return {
          data: data.data['phase_mitigations'].map((phaseMitigation: any) => {
            const mitigation = data.data['mitigations'].find(
              (mitigation: any) => mitigation.id === phaseMitigation.mitigation_id
            );
            return {
              ...mitigation,
              mitigation_id: mitigation.id,
              phase_mitigation_id: phaseMitigation.id
            };
          })
        };
      }
    }
  });

  const { data: mitigations } = useList({
    resource: 'mitigations',

    pagination: {
      pageSize: 10000
    }
  });

  const addNewPhaseMitigation = async (phaseId: string, mitigationId: string) => {
    try {
      await createAsync({
        resource: 'phase_mitigations',
        values: {
          company: ATTACKIQ_ID,
          phase: phaseId,
          mitigation: mitigationId
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      refetchPhaseMitigations();
    }
  };

  const removePhaseMitigation = async (phaseMitigationId: string) => {
    try {
      await deleteAsync({
        resource: 'phase_mitigations',
        id: phaseMitigationId
      });
    } catch (e) {
      console.error(e);
    } finally {
      refetchPhaseMitigations();
    }
  };

  const mitigationOptions =
    mitigations?.data?.filter(
      (mitigation: any) =>
        !phaseMitigations?.data.find((phaseMitigation: any) => {
          return phaseMitigation.mitigation_id === mitigation.id;
        })
    ) || [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <ul>
        {phaseMitigations?.data?.map((mitigation: any) => (
          <Box
            component="li"
            sx={{
              border: '1px dashed gray',
              padding: '0.5rem 1rem',
              marginBottom: '0.5rem',
              backgroundColor: 'background.paper'
            }}
            key={mitigation.phase_mitigation_id}
          >
            <details>
              <summary>
                <Typography variant="body1" component="span">
                  {mitigation.name}
                </Typography>
              </summary>

              <Typography variant="body2">{mitigation.description}</Typography>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <IconButton
                  aria-label="delete mitigation"
                  onClick={() => removePhaseMitigation(mitigation.phase_mitigation_id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </details>
          </Box>
        ))}
      </ul>
      <Autocomplete
        disablePortal
        options={mitigationOptions}
        getOptionLabel={(option: any) => option.name}
        value={null}
        inputValue={mitigationSearchValue}
        onInputChange={(_event, value) => setMitigationSearchValue(value)}
        renderInput={(params: any) => <TextField {...params} label="Add mitigation" />}
        onChange={(_event, newlySelectedMitigation) => {
          addNewPhaseMitigation(phaseId as string, newlySelectedMitigation?.id as string);
          setMitigationSearchValue('');
        }}
      />
    </Box>
  );
};

export default PhaseMitigations;
