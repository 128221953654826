import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';

import type { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';

export type TextFieldInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & TextFieldProps;

export const TextFieldInput = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  name,
  control,
  rules,
  defaultValue,
  helperText,
  onChange,
  ...TextFieldProps
}: TextFieldInputProps<TFieldValues, TName>) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  return (
    <TextField
      {...field}
      onChange={(...args) => {
        field.onChange(...args);
        onChange?.(...args);
      }}
      error={!!error}
      helperText={error?.message || helperText}
      variant="outlined"
      color="secondary"
      {...TextFieldProps}
    />
  );
};
