import { useRef, useState } from 'react';
import { Cancel, ClearOutlined, DownloadForOffline, DownloadOutlined } from '@mui/icons-material';
import {
  alpha,
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip
} from '@mui/material';
import { FieldProps } from '@rjsf/core';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosProgressEvent } from 'axios';

export const FileUploadField = (props: FieldProps) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { mutateAsync, isPending } = useUploadFile();

  const { autofocus, disabled, name, rawErrors, required, schema, uiSchema, onChange } = props;
  const { accept, multiple } = (uiSchema['ui:options'] ?? {}) as { accept?: string; multiple?: boolean };
  const hasValue = !!uploadedFiles.length;
  const hasError = !!rawErrors?.length;

  const handleChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
    onChange(files.length ? getModelValue(files) : null);
  };

  return (
    <>
      <Box
        component="input"
        type="file"
        ref={fileInputRef}
        accept={accept}
        multiple={multiple}
        onChange={async event => {
          const files = event.target.files;
          const promises = Object.values(files ?? {}).map(file => mutateAsync(file));
          const uploadResults = await Promise.all(promises);

          handleChange(uploadResults);

          // Clear target value to be able to re-upload the file or upload a new file with the same name.
          event.target.value = '';
        }}
        sx={{ display: 'none !important' }}
        hidden
      />

      <Autocomplete
        value={multiple ? uploadedFiles : uploadedFiles[0] ?? null}
        options={uploadedFiles}
        autoFocus={autofocus}
        disabled={disabled || isPending}
        multiple={multiple}
        getOptionLabel={option => getFileName(option.file)}
        renderInput={props => {
          return (
            <TextField
              name={name}
              error={hasError}
              required={required}
              label={schema.title}
              {...props}
              InputProps={{
                ...props.InputProps,
                onClick: () => fileInputRef.current?.click(),
                ...(hasValue && {
                  endAdornment: (
                    <InputAdornment position="end">
                      {!multiple && (
                        <Tooltip title="Download" disableInteractive arrow>
                          <IconButton
                            size="small"
                            href={uploadedFiles[0].file}
                            target="_blank"
                            onClick={event => event.stopPropagation()}
                            sx={{ p: 0.5 }}
                          >
                            <DownloadOutlined fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Tooltip title={!disabled ? 'Clear' : ''} disableInteractive arrow>
                        <span>
                          <IconButton
                            size="small"
                            disabled={disabled}
                            onClick={event => {
                              event.stopPropagation();
                              handleChange([]);
                            }}
                            sx={{ p: 0.5 }}
                          >
                            <ClearOutlined fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </InputAdornment>
                  )
                }),
                ...(isPending && {
                  endAdornment: (
                    <InputAdornment position="end" sx={{ width: 28, height: 28, justifyContent: 'center' }}>
                      <CircularProgress color="secondary" size={18} />
                    </InputAdornment>
                  )
                })
              }}
            />
          );
        }}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => {
            const { key, onDelete, ...tagProps } = getTagProps({ index });

            return (
              <Chip
                key={option.id}
                label={getFileName(option.file)}
                onDelete={() => null}
                deleteIcon={
                  <Box
                    sx={{
                      color: 'unset',
                      display: 'flex',

                      '& svg': theme => ({
                        color: alpha(theme.palette.text.primary, 0.26),

                        '&:hover': {
                          color: alpha(theme.palette.text.primary, 0.4)
                        }
                      })
                    }}
                  >
                    <Tooltip title="Download" disableInteractive arrow>
                      <DownloadForOffline fontSize="inherit" onClick={() => window.open(option.file, '_blank')} />
                    </Tooltip>
                    <Tooltip title="Clear" disableInteractive arrow>
                      <Cancel
                        fontSize="inherit"
                        onClick={() => {
                          const newUploadResults = uploadedFiles.filter(uploadResult => uploadResult.id !== option.id);

                          handleChange(newUploadResults);
                        }}
                      />
                    </Tooltip>
                  </Box>
                }
                {...tagProps}
              />
            );
          });
        }}
        PopperComponent={() => null}
        forcePopupIcon={false}
        disableClearable
      />
    </>
  );
};

interface UseUploadFileParams {
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

interface UploadedFile {
  id: string;
  meta_data: {
    mime_type: string;
  };
  file: string;
  company: {
    id: string;
    name: string;
    display_name: string;
  };
  user: string;
  created: string;
  modified: string;
  sha1: string;
}

const useUploadFile = ({ onUploadProgress }: UseUploadFileParams = {}) => {
  return useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData();

      formData.append('file', file);

      const { data } = await axios.post<UploadedFile>('/v1/files', formData, {
        onUploadProgress,
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': window?.['csrfToken']
        }
      });

      return data;
    }
  });
};

const getFileName = (path: string) => {
  return new URL(path).pathname.split('/').at(-1) ?? '';
};

const getModelValue = (uploadResults: UploadedFile[]) => {
  return uploadResults.map(({ id, file }) => `${id}/${getFileName(file)}`).join(',');
};
