import { Box, Chip, Skeleton, Typography } from '@mui/material';
import { Show } from '@refinedev/mui';

import useCurrentResource from '../../hooks/useCurrentResource';
import { New } from '../../types';

const NewShow = () => {
  const { data: newContent, isLoading } = useCurrentResource<New>();

  return (
    <Show>
      {isLoading ? (
        <Skeleton width={600} height={400} variant="rectangular" />
      ) : (
        <Box
          sx={{
            backgroundImage: `url(${newContent?.cover_image})`,
            minHeight: 500,
            width: 600,
            backgroundSize: 'cover',
            p: 2
          }}
        >
          <Box sx={{ bgcolor: '#000000b3', p: 2 }} bgcolor="black">
            <Typography variant="h5">{newContent?.title}</Typography>
            <Typography variant="body2" color="text.secondary">
              Content ID: {newContent?.content_id}
            </Typography>
            <Typography sx={{ mt: 2 }}>{newContent?.description}</Typography>{' '}
            {newContent?.featured && <Chip sx={{ mt: 2 }} label="Featured" color="secondary" />}
          </Box>
        </Box>
      )}
    </Show>
  );
};

export default NewShow;
