import { forwardRef, useImperativeHandle } from 'react';
import { UploadFile } from '@mui/icons-material';
import { Avatar, Box, BoxProps, Skeleton } from '@mui/material';
import { cx } from '@emotion/css';
import { useSnackbar } from 'notistack';
import prettyBytes from 'pretty-bytes';
import { DropzoneOptions, ErrorCode, FileError, useDropzone } from 'react-dropzone';
import * as R from 'remeda';

type ValidatorFn = DropzoneOptions['validator'];

type CustomErrorCodes =
  | 'name-too-large'
  | 'name-too-short'
  | 'size-too-large'
  | 'size-too-small'
  | ErrorCode.FileInvalidType
  | ErrorCode.TooManyFiles;

interface DropzoneProps extends Omit<DropzoneOptions, 'validator' | 'accept' | 'maxFiles'> {
  children: Iterable<React.ReactNode> | React.ReactNode;
  width?: number | string;
  height: number | string;
  isLoading?: boolean;
  isErrored?: boolean;
  rules?: {
    maxLength?: number | null;
    minLength?: number | null;
    maxSize?: number | null;
    minSize?: number | null;
    maxFiles?: DropzoneOptions['maxFiles'];
    accept?: DropzoneOptions['accept'];
  };
  customValidator?: ValidatorFn;
  customErrorMessages?: {
    [key in CustomErrorCodes]?: string;
  };
  RootProps?: Omit<BoxProps, 'width' | 'height'>;
}

interface DropzoneHandle {
  open: () => void;
}

const DropzoneComponent: React.ForwardRefRenderFunction<DropzoneHandle, DropzoneProps> = (props, ref) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    children,
    width = '100%',
    height,
    isLoading,
    isErrored,
    rules = {},
    customValidator,
    customErrorMessages,
    RootProps,
    ...dropzoneOptions
  } = props;
  const { disabled, multiple = false, onDrop, onDropAccepted, onDropRejected } = dropzoneOptions;
  const { accept, maxFiles } = rules;

  const validate: ValidatorFn = file => {
    rules.maxLength ??= null;
    rules.minLength ??= null;
    rules.maxSize ??= null;
    rules.minSize ??= null;

    const { maxLength, minLength, maxSize, minSize } = rules;

    const errors: FileError[] = [];
    const customErrors = customValidator?.(file) ?? [];

    if (maxLength !== null && file.name.length > maxLength) {
      errors.push({
        code: 'name-too-large',
        message: `${file.name} is larger than ${maxLength} characters`
      });
    }

    if (minLength !== null && file.name.length < minLength) {
      errors.push({
        code: 'name-too-short',
        message: `${file.name} is shorter than ${minLength} characters`
      });
    }

    if (maxSize !== null && file.size > maxSize) {
      errors.push({
        code: 'size-too-large',
        message: `${prettyBytes(file.size)} is larger than ${prettyBytes(maxSize)}`
      });
    }

    if (minSize !== null && file.size < minSize) {
      errors.push({
        code: 'size-too-small',
        message: `${prettyBytes(file.size)} is smaller than ${prettyBytes(minSize)}`
      });
    }

    const allErrors = [...errors, ...(Array.isArray(customErrors) ? customErrors : [customErrors])];

    return allErrors.length > 0 ? allErrors : null;
  };

  const handleDropRejected: DropzoneOptions['onDropRejected'] = (rejectedFiles, event) => {
    const errors = R.uniqBy(rejectedFiles.map(({ errors }) => errors).flat(), error => error.message);
    errors.forEach(error => {
      const message = customErrorMessages?.[error.code] ?? error.message;

      enqueueSnackbar({ message, variant: 'warning' });
    });

    onDropRejected?.(rejectedFiles, event);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept,
    multiple,
    maxFiles,
    onDrop,
    onDropAccepted,
    onDropRejected: handleDropRejected,
    disabled: disabled || isLoading,
    validator: validate
  });

  useImperativeHandle(ref, () => ({ open }));

  if (isLoading) return <Skeleton variant="rectangular" width={width} height={height} />;

  return (
    <Box
      className={cx('Dropzone', { disabled, active: isDragActive, loading: isLoading, error: isErrored })}
      width={width}
      height={height}
      {...RootProps}
      sx={{
        border: '1px dashed',
        borderColor: 'divider',
        borderRadius: 1,
        position: 'relative',
        cursor: 'pointer',
        backgroundColor: 'inherit',
        transition: theme => {
          return theme.transitions.create(['border', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.evenShortest
          });
        },

        '&.active, &:hover': {
          borderColor: 'primary.main',
          backgroundColor: 'primary.selected'
        },

        '&.error': {
          border: '1px solid',
          borderColor: 'error.main',
          backgroundColor: 'error.hover',

          '& .upload-icon': {
            color: 'error.main'
          }
        },

        '&.disabled': {
          cursor: 'not-allowed',
          borderColor: 'divider',
          color: 'text.disabled',

          '& .upload-icon': {
            color: 'text.disabled'
          }
        },
        ...RootProps?.sx
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      {(Array.isArray(children) ? children : [children])?.map(child => {
        if (typeof child === 'function') {
          return child({ isDragActive });
        }

        return child;
      })}
    </Box>
  );
};

const Body = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        ...props['sx']
      }}
    >
      <Avatar sx={{ backgroundColor: 'inherit' }}>
        <UploadFile className="upload-icon" color="primary" />
      </Avatar>

      {children}
    </Box>
  );
};

interface CoverProps extends BoxProps {
  in?: boolean;
}

const Cover = ({ in: $in = false, children, ...props }: CoverProps) => {
  if (!$in) return null;

  return (
    <Box
      {...props}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        ...props['sx']
      }}
    >
      {children}
    </Box>
  );
};

Body.displayName = 'DropzoneBody';
Cover.displayName = 'DropzoneCover';

export const Dropzone = Object.assign(forwardRef(DropzoneComponent), { Body, Cover, displayName: 'Dropzone' });
