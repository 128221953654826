import { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigation, useNotification } from '@refinedev/core';
import { SaveButton } from '@refinedev/mui';
import { UseModalFormReturnType } from '@refinedev/react-hook-form';

import httpClient from '../../../utils/axios';

const ImportAssessmentTemplateModal: React.FC<UseModalFormReturnType> = ({
  modal: { visible, close },
  handleSubmit
}) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined | null>(null);
  const { open } = useNotification();
  const { edit } = useNavigation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file);
  };

  const onSubmit = handleSubmit(async () => {
    if (!selectedFile) {
      return null;
    }

    try {
      const formData = new FormData();
      formData.append('json_file', selectedFile);

      const { data } = await httpClient.post('/v1/assessment_templates/import_json', formData);

      open?.({
        type: 'success',
        message: 'Assessment template imported successfully'
      });
      edit('assessment_templates', data.id);
    } catch (e) {
      open?.({
        // @ts-ignore: add description to ignore
        message: e.response.data.detail,
        type: 'error'
      });
    }
  });

  return (
    <Dialog open={visible} onClose={close} PaperProps={{ sx: { minWidth: 500 } }}>
      <DialogTitle>Import assessment template via JSON</DialogTitle>
      <DialogContent>
        <Box component="form" autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <input type="file" id="file" onChange={handleFileChange} accept="application/json, application/zip" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton onClick={onSubmit} disabled={!selectedFile}>
          Import
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
};

export default ImportAssessmentTemplateModal;
