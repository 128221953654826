import React from 'react';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { createRoot } from 'react-dom/client';

import App from './App';

LicenseInfo.setLicenseKey(
  'cdc12dc3ceadefe2b220907420948d29Tz03OTQ0MyxFPTE3MzI1NDQ3MjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const container = document.getElementById('root')!;

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
