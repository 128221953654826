import { useRef } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { useActive, useCommands } from '@remirror/react';

import { useBoolean } from '@attackiq/hooks';

import ButtonBase from './ButtonBase';

const MoreFormattingButtonMenu = () => {
  const anchorElRef = useRef();
  const [isOpen, { on: openMenu, off: closeMenu }] = useBoolean(false);
  const { spacing } = useTheme();
  const { focus, removeMark, ...commands } = useCommands();
  const active = useActive(true);

  const handleClearFormatting = () => {
    closeMenu();
    removeMark({ type: null });
    focus();
  };

  return (
    <>
      <ButtonBase ref={anchorElRef} id="more-formatting-menu-button" active={isOpen} onClick={openMenu}>
        <MoreHoriz />
      </ButtonBase>
      <Menu
        id="more-formatting-menu"
        aria-labelledby="more-formatting-menu-button"
        anchorEl={anchorElRef.current}
        open={isOpen}
        onClose={closeMenu}
      >
        {formats.map(({ displayName, getActive, getToggle }) => {
          const handleClick = () => {
            closeMenu();
            getToggle(commands)();
            focus();
          };

          return (
            <MenuItem
              key={displayName}
              selected={getActive(active)}
              disabled={!getToggle(commands).enabled()}
              onClick={handleClick}
            >
              <Typography sx={{ fontSize: spacing(1.75) }}>{displayName}</Typography>
            </MenuItem>
          );
        })}

        <MenuItem
          //This one is working but throws an out of range error and white screen in several cases, consider adding an ErrorBoundary if want to enable it
          // disabled={!removeMark.enabled({ type: null })}
          onClick={handleClearFormatting}
        >
          <Typography sx={{ fontSize: spacing(1.75) }}>Clear formatting</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const formats = [
  {
    getActive: ({ underline }) => underline(),
    getToggle: ({ toggleUnderline }) => toggleUnderline,
    displayName: 'Underline'
  },
  {
    getActive: ({ strike }) => strike(),
    getToggle: ({ toggleStrike }) => toggleStrike,
    displayName: 'Strikethrough'
  },
  {
    getActive: ({ code }) => code(),
    getToggle: ({ toggleCode }) => toggleCode,
    displayName: 'Code'
  },
  {
    getActive: ({ sub }) => sub(),
    getToggle: ({ toggleSubscript }) => toggleSubscript,
    displayName: 'Subscript'
  },
  {
    getActive: ({ sup }) => sup(),
    getToggle: ({ toggleSuperscript }) => toggleSuperscript,
    displayName: 'Superscript'
  }
];

export default MoreFormattingButtonMenu;
