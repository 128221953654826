import { useMemo } from 'react';
import { Grid, Card, CardHeader, CardContent, Box, TextField, Button, Stack, Typography, Tooltip } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { CrudFilters, getDefaultFilter } from '@refinedev/core';
import { List, useDataGrid, DateField, DeleteButton, EditButton } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

const ScenarioTemplateList = () => {
  const { dataGridProps, filters, search } = useDataGrid({
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { search } = params;

      filters.push({
        field: 'search',
        operator: 'eq',
        value: search
      });

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'search',
          operator: 'eq',
          value: ''
        }
      ]
    }
  });

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: params => {
          return (
            <Tooltip title={params.row.name}>
              <Typography variant="body1" noWrap>
                {params.row.name}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1
      },
      {
        field: 'scenario_type',
        headerName: 'Type',
        flex: 1,
        valueFormatter: ({ value }) => (value === 1 ? 'Attack' : 'Validation')
      },
      {
        field: 'version',
        headerName: 'Version',
        flex: 1
      },
      {
        field: 'created',
        headerName: 'Created At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1,
        sortable: false
      },
      {
        field: 'modified',
        headerName: 'Updated At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1,
        sortable: false
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 250,
        sortable: false,
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        }
      }
    ],
    []
  );

  const { control, handleSubmit } = useForm({
    defaultValues: {
      search: getDefaultFilter('search', filters, 'eq') || ''
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <Card>
          <CardHeader title="Filters" />
          <CardContent>
            <Box
              component="form"
              autoComplete="off"
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
              onSubmit={handleSubmit(search)}
            >
              <Controller
                name="search"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    label="Search"
                    placeholder="Search by name"
                    margin="normal"
                    fullWidth
                    autoFocus
                  />
                )}
              />

              <br />
              <Button type="submit" variant="contained">
                Apply
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={9}>
        <List>
          <DataGrid {...dataGridProps} filterModel={undefined} disableColumnFilter autoHeight columns={columns} />
        </List>
      </Grid>
    </Grid>
  );
};

export default ScenarioTemplateList;
