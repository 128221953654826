import { Chip, ChipProps, SxProps } from '@mui/material';
import { cx } from '@emotion/css';

import withSxStyles from '../withSxStyles/withSxStyles';

interface IAiChipProps extends ChipProps {
  styles?: Record<string, SxProps>;
  severity: 'info' | 'low' | 'medium' | 'high' | 'critical' | 'idle' | 'other';
}

const AiChip = ({ styles, severity, ...props }: IAiChipProps) => {
  return (
    <Chip
      sx={styles?.[severity]}
      size="small"
      classes={{ icon: 'AiChip-icon' }}
      className={cx('AiChip-root', {
        'AiChip-severityInfo': severity === 'info',
        'AiChip-severityLow': severity === 'low',
        'AiChip-severityMedium': severity === 'medium',
        'AiChip-severityHigh': severity === 'high',
        'AiChip-severityCritical': severity === 'critical',
        'AiChip-severityIdle': severity === 'idle',
        'AiChip-severityOther': severity === 'other'
      })}
      {...props}
    />
  );
};

const AiChipWithSxStyles = withSxStyles<'div', IAiChipProps>(
  {
    info: {
      backgroundColor: 'info.lightBg',
      color: 'info.darkText',

      '& .AiChip-icon': {
        color: 'info.main'
      }
    },
    low: {
      backgroundColor: 'success.lightBg',
      color: 'success.darkText',

      '& .AiChip-icon': {
        color: 'success.main'
      }
    },
    medium: {
      backgroundColor: '#FFF7AE',
      color: 'warning.darkText',

      '& .AiChip-icon': {
        color: '#D29700'
      }
    },
    high: {
      backgroundColor: 'warning.lightBg',
      color: 'warning.darkText',

      '& .AiChip-icon': {
        color: 'warning.main'
      }
    },
    critical: {
      backgroundColor: 'error.lightBg',
      color: 'error.darkText',

      '& .AiChip-icon': {
        color: 'error.main'
      }
    },
    idle: {
      backgroundColor: 'grey.200',
      color: 'grey.800',

      '& .AiChip-icon': {
        color: 'text.secondary'
      }
    },
    other: {
      backgroundColor: 'secondary.lightBg',
      color: 'secondary.dark',

      '& .AiChip-icon': {
        color: 'secondary.main'
      }
    }
  },
  { name: 'AiChip' }
)(AiChip);

export default AiChipWithSxStyles;
