import { Box, Button, Paper, Typography } from '@mui/material';
import { atom } from 'jotai';

import { useBoolean } from '@attackiq/hooks';

import DescriptorJSONEditor from './components/DescriptorJSONEditor';
import ParameterLibraryDialog from './components/ParameterLibraryDialog';
import UIPreview from './components/UIPreview';

const ParameterModelerList = () => {
  const [isParameterLibraryDialogOpen, { on: openParameterLibraryDialog, off: closeParameterLibraryDialog }] =
    useBoolean(false);

  return (
    <>
      <Paper sx={{ height: '100%', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
        <Typography variant="h6">
          This tool is used during integration development to create and layout the parameters for your integration.
        </Typography>

        <Button variant="outlined" onClick={openParameterLibraryDialog} sx={{ alignSelf: 'flex-end' }}>
          Parameter Library
        </Button>

        <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
          <DescriptorJSONEditor />

          <UIPreview />
        </Box>
      </Paper>

      <ParameterLibraryDialog open={isParameterLibraryDialogOpen} onClose={closeParameterLibraryDialog} />
    </>
  );
};

export const schemaAtom = atom<{ form?: Record<PropertyKey, unknown>[]; config?: Record<PropertyKey, unknown> }>({});
export const toggleAtom = atom(false);

export default ParameterModelerList;
