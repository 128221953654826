import { useCallback, useState } from 'react';

type UseBooleanReturn = [boolean, { on: () => void; off: () => void; toggle: () => void }];

function useBoolean(initialState = false): UseBooleanReturn {
  const [value, setValue] = useState(initialState);

  const on = useCallback(() => {
    setValue(true);
  }, []);

  const off = useCallback(() => {
    setValue(false);
  }, []);

  const toggle = useCallback(() => {
    setValue(prev => !prev);
  }, []);

  return [value, { on, off, toggle }];
}

export default useBoolean;
