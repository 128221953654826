import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useCreate, useNavigation } from '@refinedev/core';
import { Create, useAutocomplete } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { isEqual } from 'lodash';
import { FormProvider, useFormContext, useWatch } from 'react-hook-form';

import { ATTACKIQ_ID, MODULE_CONTENT_MAP } from '@attackiq/constants';

import AiAutocompleteInput from '../../components/inputs/AutocompleteInput/AutocompleteInput';
import useSearchParams from '../../hooks/useSearchParams';
import { BlueprintTemplate, ProjectTemplate, Scenario, AttackGraphTemplate, FlexPackage } from '../../types';

const contentTypeOptions = Object.entries(MODULE_CONTENT_MAP).map(([key, value]) => ({
  model: key,
  displayName: value
}));

const ModuleContentCreate = () => {
  const { saveButtonProps, ...formMethods } = useForm({
    refineCoreProps: {
      resource: 'module_contents',
      action: 'create',
      redirect: false
    }
  });
  const { show } = useNavigation();
  const { mutateAsync: createAsync } = useCreate();

  const { handleSubmit } = formMethods;

  const onSubmit = handleSubmit(async ({ content, content_type, module }) => {
    const promises = content.map(async content => {
      await createAsync({
        resource: 'module_contents',
        values: {
          module_id: module.id,
          content_id: content.id,
          content_type_model: content_type.model,
          company_id: ATTACKIQ_ID,
          content_type_app_label: 'ai_cloud'
        }
      });
    });

    await Promise.all(promises);
    show('modules', module.id);
  });

  return (
    <FormProvider {...formMethods}>
      <form name="create-module-content-form" onSubmit={onSubmit}>
        <Create saveButtonProps={{ type: 'submit' }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <ModuleInput />
            <ContentTypeInput />
            <ContentInput />
          </Box>
        </Create>
      </form>
    </FormProvider>
  );
};

const ModuleInput = () => {
  const { setValue } = useFormContext();
  const { autocompleteProps } = useAutocomplete<any>({
    resource: 'modules',
    fetchSize: 10000,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'search',
        operator: 'eq',
        value: searchText
      }
    ]
  });
  const { options } = autocompleteProps;

  const { module_id } = useSearchParams();

  useEffect(() => {
    if (module_id && options) {
      const currentModule = options.find(option => option.id === module_id) || null;

      setValue('module', currentModule);
    }
  }, [setValue, module_id, options]);

  return (
    <AiAutocompleteInput
      {...autocompleteProps}
      sx={{ mt: 0.5 }}
      name="module"
      defaultValue={null}
      getOptionLabel={option => option.display_name || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      TextFieldProps={{
        label: 'Module'
      }}
    />
  );
};

const ContentTypeInput = () => {
  const contentType = useWatch({ name: 'content_type', defaultValue: null });
  const { setValue } = useFormContext();

  return (
    <AiAutocompleteInput
      name="content_type"
      defaultValue={null}
      options={contentTypeOptions}
      onChange={(_event, value) => {
        if (!isEqual(value, contentType)) {
          setValue('content', []);
        }
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') return option;

        return option.displayName || '';
      }}
      isOptionEqualToValue={(option, value) => option.model === value.model}
      TextFieldProps={{
        label: 'Content Type'
      }}
    />
  );
};

const ContentInput = () => {
  const contentType: (typeof contentTypeOptions)[number] = useWatch({ name: 'content_type', defaultValue: null });
  const isScenario = contentType?.model === 'scenario';
  const isAssessmentTemplate = contentType?.model === 'projecttemplate';
  const isBlueprint = contentType?.model === 'blueprinttemplate';
  const isAttackGraphTemplate = contentType?.model === 'attackgraphtemplate';
  const isFlexPackage = contentType?.model === 'flexpackage';
  const { autocompleteProps: scenariosAutocompleteProps } = useAutocomplete<Scenario>({
    resource: 'scenarios',
    fetchSize: 20,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'search',
        operator: 'eq',
        value: searchText
      }
    ]
  });
  const { autocompleteProps: templatesAutocompleteProps } = useAutocomplete<ProjectTemplate>({
    resource: 'assessment_templates',
    fetchSize: 20,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'search',
        operator: 'eq',
        value: searchText
      }
    ]
  });
  const { autocompleteProps: blueprintsAutocompleteProps } = useAutocomplete<BlueprintTemplate>({
    resource: 'blueprint_templates',
    fetchSize: 20,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'search',
        operator: 'eq',
        value: searchText
      }
    ]
  });
  const { autocompleteProps: attackgraphTemplatesAutocompleteProps } = useAutocomplete<AttackGraphTemplate>({
    resource: 'attack_graph_templates',
    fetchSize: 20,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'search',
        operator: 'eq',
        value: searchText
      }
    ]
  });

  const { autocompleteProps: FlexPackageAutocompleteProps } = useAutocomplete<FlexPackage>({
    resource: 'flex_packages',
    fetchSize: 20,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'search',
        operator: 'eq',
        value: searchText
      }
    ]
  });

  const getAutocompleteProps = () => {
    if (isScenario) {
      return scenariosAutocompleteProps;
    }

    if (isAssessmentTemplate) {
      return templatesAutocompleteProps;
    }

    if (isBlueprint) {
      return blueprintsAutocompleteProps;
    }

    if (isAttackGraphTemplate) {
      return attackgraphTemplatesAutocompleteProps;
    }

    if (isFlexPackage) {
      return FlexPackageAutocompleteProps;
    }

    return {
      options: []
    };
  };

  return (
    <AiAutocompleteInput
      {...getAutocompleteProps()}
      name="content"
      defaultValue={[]}
      disabled={!contentType}
      getOptionLabel={option => option.name || option.template_name || ''}
      TextFieldProps={{ label: 'Content' }}
      multiple
    />
  );
};

export default ModuleContentCreate;
