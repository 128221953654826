interface HealthcheckMetadata {
  id: string;
  description: string;
}

interface HealthchecksMetadata {
  directory: HealthcheckMetadata;
  disk: HealthcheckMetadata;
  python: HealthcheckMetadata;
  safeupdate: HealthcheckMetadata;
}

export type HealthcheckKey = keyof HealthchecksMetadata;

export const HEALTHCHECKS_METADATA: HealthchecksMetadata = {
  directory: { id: 'directory_healthcheck', description: 'Directory Health' },
  disk: { id: 'disk_healthcheck', description: 'Free Disk Space' },
  python: { id: 'python_healthcheck', description: 'Python Interpreter Health' },
  safeupdate: { id: 'safeupdate_healthcheck', description: 'Safeupdate Health' }
};

export const HEALTHCHECK_KEYS = Object.keys(HEALTHCHECKS_METADATA) as HealthcheckKey[];
