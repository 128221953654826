import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { SaveButton } from '@refinedev/mui';
import { UseModalFormReturnType } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

import { ATTACKIQ_ID } from '@attackiq/constants';

import AssessmentTemplateTypeSelector from '../../../components/inputs/AssessmentTemplateTypeSelector/AssessmentTemplateTypeSelector';
import VendorProductSelector from '../../../components/inputs/VendorProductSelector/VendorProductSelector';

const CreateAssessmentTemplateModal: React.FC<UseModalFormReturnType> = ({
  saveButtonProps,
  modal: { visible, close, title },
  register,
  control,
  handleSubmit,
  refineCore: { onFinish },
  formState: { errors }
}) => {
  const onSubmit = handleSubmit(validData => {
    // TODO:
    // Project name and Project description are what the assessments using this template will be called, there is no need for these to exist because we use the template name and description
    // But the serializer needs this at the moment.
    onFinish({
      ...validData,
      project_id: null, // This shouldnt be required
      project_name: validData.template_name,
      project_description: validData.template_description,
      company: ATTACKIQ_ID
    });
    close();
  });

  return (
    <Dialog open={visible} onClose={close} PaperProps={{ sx: { minWidth: 500 } }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" color="GrayText">
          You will be able to edit more details after you save this
        </Typography>
        <Box component="form" autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Controller
            rules={{ required: 'Assessment template name is required' }}
            control={control}
            name="template_name"
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                error={!!errors.template_name}
                helperText={errors.template_name?.message}
                margin="normal"
                label="Assessment Template Name"
                fullWidth
                required
                autoFocus
              />
            )}
          />

          <Controller
            rules={{ required: 'Assessment template description is required' }}
            control={control}
            name="template_description"
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                error={!!errors.template_description}
                helperText={errors.template_description?.message}
                placeholder="Assessment Template Description"
                label="Assessment Template Description"
                required
                rows={4}
              />
            )}
          />

          <VendorProductSelector control={control} name="vendor_product_id" />

          <AssessmentTemplateTypeSelector
            control={control}
            rules={{
              required: 'Assessment template type is required'
            }}
            inputProps={{
              error: !!errors.project_template_type,
              helperText: errors.project_template_type?.message,
              required: true
            }}
            name="project_template_type"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={onSubmit} />
      </DialogActions>
    </Dialog>
  );
};

export default CreateAssessmentTemplateModal;
