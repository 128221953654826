import { LabelTwoTone } from '@mui/icons-material';
import { Chip, useTheme } from '@mui/material';
import {
  amber,
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow
} from '@mui/material/colors';

import { CHARCOAL } from '@attackiq/constants';

export const BUSINESS_ENTITY_COLOR_MAP = {
  charcoal: CHARCOAL,
  blue,
  deepPurple,
  indigo,
  lightBlue,
  purple,
  pink,
  deepOrange,
  red,
  orange,
  amber,
  yellow,
  lime,
  cyan,
  teal,
  green,
  lightGreen
} as const;

export const getBusinessEntityColorObject = (
  color?: string
): (typeof BUSINESS_ENTITY_COLOR_MAP)[keyof typeof BUSINESS_ENTITY_COLOR_MAP] => {
  return BUSINESS_ENTITY_COLOR_MAP[color || 'charcoal'];
};

interface ComponentsProps {
  label: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  color: keyof typeof BUSINESS_ENTITY_COLOR_MAP | (string & {});
}

export const BusinessEntityChip = ({ label, color }: ComponentsProps) => {
  const colorObject = getBusinessEntityColorObject(color);

  return (
    <Chip
      size="small"
      variant="outlined"
      label={label}
      sx={theme =>
        theme.palette.mode === 'dark'
          ? {
              color: colorObject[200],
              borderColor: colorObject[200]
            }
          : {
              color: 'text.secondary',
              borderColor: colorObject[700],
              backgroundColor: colorObject[100]
            }
      }
    />
  );
};

export const BusinessEntityLabel = ({ label, color }: ComponentsProps) => {
  const { palette } = useTheme();
  const colorObject = getBusinessEntityColorObject(color);

  return (
    <>
      {label} <LabelTwoTone htmlColor={palette.mode === 'dark' ? colorObject[200] : colorObject[700]} />
    </>
  );
};
