import { useRef } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Button, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { useActive, useCommands } from '@remirror/react';

import { useBoolean } from '@attackiq/hooks';

const HeadingButtonMenu = () => {
  const anchorElRef = useRef();
  const [isOpen, { on: openMenu, off: closeMenu }] = useBoolean(false);
  const { spacing } = useTheme();
  const { toggleHeading, focus } = useCommands();
  const { heading } = useActive();

  return (
    <>
      <Button
        id="heading-menu-button"
        size="small"
        endIcon={<ExpandMore />}
        ref={anchorElRef}
        onClick={openMenu}
        sx={{ width: 130 }}
      >
        {variants.map(({ level, displayName }) => {
          return (level ? heading({ level }) : !heading()) && displayName;
        })}
      </Button>
      <Menu
        id="heading-menu"
        aria-labelledby="heading-menu-button"
        anchorEl={anchorElRef.current}
        open={isOpen}
        onClose={closeMenu}
      >
        {variants.map(({ level, displayName, variant, sizeUnits }) => {
          const isActive = level ? heading({ level }) : !heading();

          const handleClick = () => {
            closeMenu();
            if (!isActive) {
              level ? toggleHeading({ level }) : toggleHeading();
            }
            focus();
          };

          return (
            <MenuItem
              key={level}
              onClick={handleClick}
              selected={heading({ level })}
              disabled={!toggleHeading.enabled({ level })}
            >
              <Typography variant={variant} sx={{ fontSize: spacing(sizeUnits) }}>
                {displayName}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const variants = [
  { level: 0, displayName: 'Normal text', variant: 'body1', sizeUnits: 1.75 },
  { level: 1, displayName: 'Heading 1', variant: 'h1', sizeUnits: 3 },
  { level: 2, displayName: 'Heading 2', variant: 'h2', sizeUnits: 2.5 },
  { level: 3, displayName: 'Heading 3', variant: 'h3', sizeUnits: 2 },
  { level: 4, displayName: 'Heading 4', variant: 'h4', sizeUnits: 1.75 },
  { level: 5, displayName: 'Heading 5', variant: 'h5', sizeUnits: 1.5 },
  { level: 6, displayName: 'Heading 6', variant: 'h6', sizeUnits: 1.375 }
];

export default HeadingButtonMenu;
