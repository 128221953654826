import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import { DEFAULT_THEME } from '@attackiq/constants';

export const themeV5 = createTheme(DEFAULT_THEME);

const AiThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={themeV5}>
      <EmotionThemeProvider theme={themeV5}>{children}</EmotionThemeProvider>
    </ThemeProvider>
  );
};

export default AiThemeProvider;
