import { useState } from 'react';
import { TabContext, TabList, TabListProps, TabPanel } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, DialogTitle, Tab, Typography } from '@mui/material';

import { ReactAsf } from '@attackiq/react-asf';

const ParameterLibraryDialog = ({ open, onClose }) => {
  const [tab, setTab] = useState<TabStateValue>(tabs[0].id);

  const handleTabChange: TabListProps['onChange'] = (_event, newValue: TabStateValue) => {
    setTab(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Parameter library and examples</DialogTitle>
      <DialogContent>
        <TabContext value={tab}>
          <TabList variant="fullWidth" onChange={handleTabChange}>
            {tabs.map(({ id, label }) => (
              <Tab key={id} label={label} value={id} />
            ))}
          </TabList>

          {tabs.map(({ id, descriptor }) => {
            const { config, form } = descriptor;

            const handleCopyToClipboard = () => {
              navigator.clipboard.writeText(JSON.stringify(descriptor, null, 2));
            };

            return (
              <TabPanel key={id} value={id}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Typography variant="h6">UI Example Preview:</Typography>
                  <ReactAsf form={form} schema={config} model={{}} />

                  <Typography variant="h6">JSON Example Preview:</Typography>
                  <Box component="pre" sx={{ border: '1px solid #ccc', m: 0, p: 2, whiteSpace: 'pre-wrap' }}>
                    {JSON.stringify(descriptor, null, 2)}
                  </Box>

                  <Button variant="outlined" onClick={handleCopyToClipboard} sx={{ alignSelf: 'flex-end' }}>
                    Copy to clipboard
                  </Button>
                </Box>
              </TabPanel>
            );
          })}
        </TabContext>
      </DialogContent>
    </Dialog>
  );
};

type TabStateValue = (typeof tabs)[number]['id'];

const tabs = [
  {
    id: 'checkBoxAndInput',
    label: 'Check Box & Input',
    className: 'check-box-and-input',
    descriptor: {
      config: {
        type: 'object',
        required: ['debugger_value', 'process_path', 'cleanup'],
        properties: {
          debugger_value: { title: 'Debugger value', type: 'string', default: 'sethc.exe' },
          process_path: {
            title: 'Process name to the new debugger',
            type: 'string',
            default: 'C:\\Windows\\System32\\cmd.exe'
          },
          cleanup: { title: 'Cleanup', type: 'boolean', default: true }
        }
      },
      form: [
        {
          key: 'debugger_value',
          type: 'text',
          feedback: false,
          placeholder: 'sethc.exe',
          validationMessage: 'Please enter valid debugger value'
        },
        {
          key: 'process_path',
          type: 'text',
          feedback: false,
          placeholder: 'C:\\Windows\\System32\\cmd.exe',
          validationMessage: 'Please enter valid process path'
        },
        {
          key: 'cleanup',
          type: 'checkbox',
          feedback: false,
          placeholder: 'Select to cleanup or not to cleanup',
          validationMessage:
            'Please select if you would like Firdrill to cleanup the attack when finished or not. The default value is to allow Firedrill to cleanup after the test'
        }
      ]
    }
  },
  {
    id: 'radioButton',
    label: 'Radio Button',
    classname: 'radio-button',
    descriptor: {
      config: {
        type: 'object',
        required: ['mimikatz_type'],
        properties: {
          mimikatz_type: {
            title: 'PowerShell Mimikatz Type: ',
            type: 'string',
            enum: ['custom', 'original'],
            default: 'original'
          }
        }
      },
      form: [
        {
          key: 'mimikatz_type',
          type: 'radios-inline',
          titleMap: [
            { value: 'original', name: 'Original' },
            { value: 'custom', name: 'Custom' }
          ]
        }
      ]
    }
  },
  {
    id: 'fileUploader',
    label: 'File Uploader',
    className: 'file-uploader',
    descriptor: {
      config: {
        type: 'object',
        required: ['ioc_file'],
        properties: { ioc_file: { title: 'IOC File', type: 'string' } }
      },
      form: [
        { key: 'ioc_file', type: 'fileupload', feedback: false, validationMessage: 'Please select one or more files' }
      ]
    }
  },
  {
    id: 'array',
    label: 'Array',
    className: 'array',
    descriptor: {
      config: { type: 'object', properties: { URL: { title: 'URLs', type: 'array', items: { type: 'string' } } } },
      form: [
        {
          key: 'URL',
          description: 'Connection type must be specified, e.g. http://',
          add: 'Add',
          style: { add: 'btn-firedrill' }
        }
      ]
    }
  },
  {
    id: 'conditional',
    label: 'Conditional',
    className: 'conditional',
    descriptor: {
      config: {
        type: 'object',
        properties: {
          target_type: {
            title: 'Target Machine',
            type: 'string',
            enum: ['domain_controller', 'specific', 'all'],
            default: 'all'
          },
          domain_controller_machine: { title: 'Windows Domain Controller', type: 'string' },
          specific_target_machine: { title: 'Remote Machine', type: 'string' },
          user_type: {
            title: 'Use Credential Hashes for: ',
            type: 'string',
            enum: ['specific', 'all'],
            default: 'all'
          },
          specific_user: { title: 'Username', type: 'string' }
        }
      },
      form: [
        {
          key: 'target_type',
          type: 'radios-inline',
          feedback: false,
          titleMap: [
            { value: 'domain_controller', name: 'Windows Domain Controller' },
            { value: 'specific', name: 'Specific Remote Machine' },
            { value: 'all', name: 'All Available Machines' }
          ]
        },
        {
          type: 'conditional',
          condition: 'vm.model.target_type === "specific"',
          items: [
            {
              key: 'specific_target_machine',
              type: 'text',
              feedback: false,
              placeholder: '192.168.2.0/24',
              validationMessage: 'Please enter a specific IP or a group of IPs in CIDR form',
              description: 'You can input a single IP, a group of IPs as in 192.168.2.0/24 or the FQDN of the hostname'
            }
          ]
        },
        {
          key: 'user_type',
          type: 'radios-inline',
          feedback: false,
          titleMap: [
            { value: 'specific', name: 'Specific User' },
            { value: 'all', name: 'All Available User Credentials' }
          ]
        },
        {
          type: 'conditional',
          condition: 'vm.model.user_type === "specific"',
          items: [
            {
              key: 'specific_user',
              type: 'text',
              feedback: false,
              placeholder: 'Administrator',
              validationMessage: 'Please enter a valid username',
              description: 'User credentials to search for. Regular expressions can be used.'
            }
          ]
        }
      ]
    }
  }
] as const;

export default ParameterLibraryDialog;
