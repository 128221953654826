import httpClient from './axios';

export const uploadFile = async (
  file: File,
  url: string,
  fieldName: string,
  method: 'post' | 'patch' | 'put' = 'post'
) => {
  const formData = new FormData();

  formData.append(fieldName, file, file.name);

  const selectedMethod = method === 'post' ? httpClient.post : method === 'put' ? httpClient.put : httpClient.patch;

  await selectedMethod(url, formData);
};
