interface IFProps {
  condition: boolean;
  children: React.ReactElement;
}

const IF = ({ children, condition }: IFProps) => {
  return condition ? children : null;
};

export default IF;
