import { useMemo } from 'react';
import ReactRouterProvider from '@refinedev/react-router-v6/legacy';

const { useLocation } = ReactRouterProvider;

const useSearchParams = () => {
  const { search } = useLocation();

  const searchParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);

    return Object.fromEntries(urlSearchParams.entries());
  }, [search]);

  return searchParams;
};

export default useSearchParams;
