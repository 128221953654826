/* eslint-disable */
import { createElement, Component } from 'react';
import kebabCase from 'lodash/kebabCase';

/**
 *
 * @param {*} componentName - angularjs directive name in camelcase
 * @param {*} componentBindings - directive scope parameters (input only), to send info out fo the directive pass a callback function
 *
 */
export default function angular2react(componentName, componentBindings) {
  class AngularJSComponent extends Component {
    constructor() {
      super();

      this.state = {
        didInitialCompile: false
      };

      this.$injector = angular.element(document.querySelector('body')).injector();
    }

    componentWillMount() {
      const scope = this.$injector.get('$rootScope').$new(true);

      this.setState({
        scope: Object.assign(scope, { props: this.props })
      });
    }

    componentWillUnmount() {
      if (!this.state.scope) {
        return;
      }
      this.state.scope.$destroy();
    }

    shouldComponentUpdate() {
      return false;
    }

    render() {
      if (!this.state.scope) {
        return <div>Loading...</div>;
      }

      const bindings = {};
      if (componentBindings) {
        componentBindings.forEach(binding => {
          bindings[kebabCase(binding)] = `props.${binding}`;
        });
      }

      return createElement(kebabCase(componentName), { ...bindings, ref: this.compile.bind(this) });
    }

    componentWillReceiveProps(props) {
      if (!this.state.scope) {
        return;
      }
      this.state.scope.props = props;
      this.digest();
    }

    compile(element) {
      if (this.state.didInitialCompile || !this.state.scope) {
        return;
      }
      this.$injector.get('$compile')(element)(this.state.scope);
      this.digest();
      this.setState({ didInitialCompile: true });
    }

    digest() {
      if (!this.state.scope) {
        return;
      }
      try {
        this.state.scope.$digest();
      } catch (e) {}
    }
  }

  AngularJSComponent.displayName = componentName;

  return AngularJSComponent;
}
