import { Edit, EditProps, ListButton } from '@refinedev/mui';

export const NoRefreshEdit = ({ children, ...props }: EditProps) => {
  return (
    <Edit
      {...props}
      headerButtons={({ listButtonProps }) => <>{listButtonProps && <ListButton {...listButtonProps} />}</>}
    >
      {children}
    </Edit>
  );
};
