import { HEALTHCHECKS_METADATA } from '@attackiq/constants';

import { Healthcheck, ParsedHealthchecks } from './types';

export const parseHealthchecks = (healthcheckResults: Healthcheck[]): ParsedHealthchecks => {
  if (!healthcheckResults || healthcheckResults?.length <= 0) return { hasErrors: false };

  const parsedHealthchecks = { hasErrors: false };
  const healthcheckKeys = Object.keys(HEALTHCHECKS_METADATA);

  healthcheckKeys.forEach(hcKey => {
    const hc = healthcheckResults.find(hcr => hcr.type === HEALTHCHECKS_METADATA[hcKey].id);
    const currentHealthcheckHasErrors = !hc?.success;

    if (hc && currentHealthcheckHasErrors) {
      parsedHealthchecks.hasErrors = parsedHealthchecks.hasErrors || currentHealthcheckHasErrors;
      parsedHealthchecks[hcKey] = hc.reason;
    }
  });

  return parsedHealthchecks;
};

export default parseHealthchecks;
