import { Box, Typography } from '@mui/material';
import { DeleteButton } from '@refinedev/mui';
import { useDrag, useDrop } from 'react-dnd';

import EditDescriptionTest from './EditDescriptionTest/EditDescriptionTest';
import ManageTestScenarios from './ManageTestScenarios/ManageTestScenarios';
import RenameTest from './RenameTest/RenameTest';

interface TestDraggableItemProps {
  id: string;
  name: string;
  description: string;
  testScenarioIds: string[];
  findTest: any;
  mutateTests: any;
  moveTest: any;
}

const TestDraggableItem: React.FC<TestDraggableItemProps> = ({
  name,
  description,
  testScenarioIds,
  findTest,
  mutateTests,
  moveTest,
  id
}) => {
  const originalIndex = findTest(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'test',
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      }),
      end: (item, monitor) => {
        // Rolls back the test if it was dropped outside an acceptable slot
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();

        if (!didDrop) {
          moveTest(droppedId, originalIndex);
          return;
        }

        // Hit the backend to update the test order
        mutateTests();
      }
    }),
    [id, moveTest, originalIndex]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'test',
      hover: ({ id: draggedId }: { id: string; originalIndex: number }) => {
        if (draggedId !== id) {
          const { index: overIndex } = findTest(id);
          moveTest(draggedId, overIndex);
        }
      }
    }),
    [id, moveTest, originalIndex]
  );

  const opacity = isDragging ? 0 : 1;

  //This should be fixed on backend so the description default value is an empty string
  const descriptionTest = description === null ? '' : description;

  return (
    <li
      ref={node => drag(drop(node))}
      style={{
        border: '1px solid #ccc',
        padding: '0.5rem 1rem',
        marginBottom: '.5rem',
        cursor: 'move',
        opacity
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography>
          {name} - ({testScenarioIds.length} Scenarios)
        </Typography>
        <Box
          sx={{
            display: 'flex'
          }}
        >
          <EditDescriptionTest testName={name} testId={id} description={descriptionTest} />
          <ManageTestScenarios testName={name} testId={id} />
          <RenameTest testName={name} testId={id} />
          <DeleteButton resource="assessment_template_tests" hideText recordItemId={id} />
        </Box>
      </Box>
    </li>
  );
};

export default TestDraggableItem;
