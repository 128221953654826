import { forwardRef } from 'react';
import { Button } from '@mui/material';

const ButtonBase = forwardRef(({ active, ...props }, ref) => {
  return (
    <Button
      size="small"
      variant={active ? 'contained' : 'text'}
      ref={ref}
      sx={{
        p: 0,
        minWidth: 32,
        minHeight: 32
      }}
      {...props}
    />
  );
});

export default ButtonBase;
