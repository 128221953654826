const getIsReactJsonSchema = (descriptorJson = {}) => {
  return 'schema' in descriptorJson || 'uiSchema' in descriptorJson;
};

export const getIsReactJsonSchemaScenarios = (descriptorResource = {}) => {
  if ('version' in descriptorResource) {
    return descriptorResource['version'] === 2;
  }
  return false;
};

export default getIsReactJsonSchema;
