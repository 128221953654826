import { Box } from '@mui/material';
import { useCreate, useNavigation, useResource } from '@refinedev/core';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { FormProvider } from 'react-hook-form';

import { ATTACKIQ_ID } from '@attackiq/constants';

import {
  CreditsInput,
  DescriptionInput,
  FeaturedCheckbox,
  NameInput,
  PackageTypeInput,
  ProjectTemplateInput,
  PurposeInput
} from './components/input_components';

const FlexPackagesCreate = () => {
  const { saveButtonProps, ...formMethods } = useForm({
    refineCoreProps: {
      resource: 'flex_packages',
      action: 'create',
      redirect: false
    }
  });
  const { mutateAsync: createAsync } = useCreate();

  const { resource } = useResource();
  const { list } = useNavigation();

  const { handleSubmit, control } = formMethods;

  const onSubmit = handleSubmit(
    async ({ name, description, package_type, project_template, credits, featured, purpose }) => {
      await createAsync({
        resource: 'flex_packages',
        values: {
          name: name,
          description: description ? description : '',
          package_type: package_type.id,
          project_template: project_template.id,
          version: '0.0.0',
          credits: credits,
          featured: featured,
          purpose: purpose,
          company_id: ATTACKIQ_ID
        }
      });
      list(resource.name);
    }
  );

  return (
    <FormProvider {...formMethods}>
      <form name="create-flex-packages-form" onSubmit={onSubmit}>
        <Create saveButtonProps={{ type: 'submit' }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <NameInput />
            <DescriptionInput />
            <PackageTypeInput isLoading={false} />
            <ProjectTemplateInput />
            <CreditsInput />
            <FeaturedCheckbox isLoading={false} control={control} />
            <PurposeInput />
          </Box>
        </Create>
      </form>
    </FormProvider>
  );
};

export default FlexPackagesCreate;
