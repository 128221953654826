import { FormatBoldSharp } from '@mui/icons-material';
import { useActive, useCommands } from '@remirror/react';

import ButtonBase from './ButtonBase';

const ToggleBoldButton = () => {
  const { toggleBold, focus } = useCommands();
  const { bold } = useActive();

  const handleClick = () => {
    toggleBold();
    focus();
  };

  return (
    <ButtonBase active={bold()} disabled={!toggleBold.enabled()} onClick={handleClick}>
      <FormatBoldSharp />
    </ButtonBase>
  );
};

export default ToggleBoldButton;
