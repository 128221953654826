import axiosInstance from '../utils/axios';

let _csrfToken: string | null = null;

async function getCsrfToken() {
  if (_csrfToken === null) {
    const response = await fetch(`/csrf`, {
      redirect: 'manual'
    });
    if (response.type === 'opaqueredirect') {
      window.location.href = '/login';
    }
    const data = await response.json();
    _csrfToken = data.csrfToken as string;
  }
  window['csrfToken'] = _csrfToken;
  return _csrfToken;
}

axiosInstance.interceptors.request.use(async config => {
  config.headers['X-CSRFToken'] = await getCsrfToken();
  return config;
});

// This dummy auth provider checks if we can retrieve the CSRF, if we do, we have the session cookie well set.
// TODO: Implement the rest of the properties when the auth is managed via JWT
const authProvider = {
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  logout: async () => {
    window.location.href = '/logout';
  },
  checkAuth: async () => {
    if (window['csrfToken']) {
      return;
    }

    await getCsrfToken();
  },
  checkError: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(),
  getUserIdentity: () => Promise.resolve()
};

export default authProvider;
