import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { SaveButton } from '@refinedev/mui';
import { UseModalFormReturnType } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { ATTACKIQ_ID } from '@attackiq/constants';

const CreatePhaseModal: React.FC<UseModalFormReturnType> = ({
  saveButtonProps,
  modal: { visible, close, title },
  control,
  handleSubmit,
  refineCore: { onFinish },
  formState: { errors }
}) => {
  const onSubmit = handleSubmit(validData => {
    // TODO: These shouldnt be required
    onFinish({
      ...validData,
      company: ATTACKIQ_ID,
      tracker_id: uuidv4()
    });
  });

  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{
        sx: {
          minWidth: 500
        }
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box component="form" autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Controller
            rules={{ required: 'Phase name is required' }}
            control={control}
            name="name"
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                error={!!errors.name}
                helperText={errors.name?.message}
                margin="normal"
                label="Phase Name"
                fullWidth
                required
                autoFocus
              />
            )}
          />

          <Controller
            rules={{ required: 'Phase description is required' }}
            control={control}
            name="description"
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                placeholder="Phase Description"
                label="Phase Description"
                fullWidth
                required
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={onSubmit} />
      </DialogActions>
    </Dialog>
  );
};

export default CreatePhaseModal;
