import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import { Backdrop, Box, Button, CircularProgress, IconButton, Modal, TextField, Typography } from '@mui/material';
import { useModal, useUpdate } from '@refinedev/core';

const RenameTest: React.FC<{ testName: string; testId: string }> = ({ testId, testName }) => {
  const { visible, show, close } = useModal();
  const [value, setValue] = useState(testName);
  const [mutationInProgress, setMutationInProgress] = useState(false);

  const { mutateAsync: updateMutateAsync } = useUpdate();
  const renameTest = async (newName: string) => {
    setMutationInProgress(true);
    try {
      await updateMutateAsync({
        resource: 'assessment_template_tests',
        id: testId,
        values: {
          name: newName
        },
        invalidates: ['all']
      });
      close();
    } finally {
      setMutationInProgress(false);
    }
  };

  return (
    <>
      <Button onClick={show}>Rename Test</Button>
      <Modal open={visible} onClose={close}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            p: 3,
            overflow: 'auto'
          }}
        >
          <Backdrop open={mutationInProgress}>
            <CircularProgress />
          </Backdrop>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography variant="h4" component="h1">
                Renaming test "{testName}"
              </Typography>
              <IconButton onClick={close}>
                <Close />
              </IconButton>
            </Box>

            <Box
              sx={{
                py: 4,
                flexGrow: 1
              }}
            >
              <TextField
                value={value}
                onChange={e => setValue(e.target.value)}
                placeholder="Enter a new name for the test"
                label="Enter a new name for the test"
                fullWidth
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                onClick={async () => {
                  await renameTest(value);
                }}
                disabled={value.length === 0 || value === testName}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RenameTest;
