import React from 'react';
import { Box } from '@mui/material';
import { TitleProps } from '@refinedev/core';

import smallLogo from '../assets/isotype-logo.png';

const AttackIQTitleComponent: React.FC<TitleProps> = ({ collapsed }) => {
  return (
    <Box
      sx={{
        width: '48px',
        height: '48px',
        p: 1
      }}
      component="picture"
    >
      <img
        style={{
          width: '100%',
          height: '100%'
        }}
        src={smallLogo}
        alt="AttackIQ"
      />
    </Box>
  );
};

export default AttackIQTitleComponent;
