import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

const angular = window.angular;

function FileFactory($q, $resource, Upload) {
  return {
    api: api,
    extractFilesFromModel: extractFilesFromModel,
    fileUpload: fileUpload,
    setFileUploads: setFileUploads,
    updateModelWithFilePaths: updateModelWithFilePaths,
    getStaticUrl: getStaticUrl
  };

  function api() {
    return $resource(
      '/v1/files/:id',
      {
        id: '@id'
      },
      {
        query: {
          method: 'GET',
          isArray: false
        },
        remove: {
          method: 'DELETE'
        }
      }
    );
  }

  function extractFilesFromModel(newValues, filesToUpload, model) {
    if (!isEmpty(newValues) && !isEmpty(filesToUpload)) {
      var fieldsToUpload = intersection(Object.keys(filesToUpload), Object.keys(newValues));
      if (!isEmpty(fieldsToUpload)) {
        angular.forEach(filesToUpload, function (value, key) {
          if (isArray(model[key]) && !isEmpty(model[key])) {
            filesToUpload[key] = filesToUpload[key].concat(model[key]); // copy the File to be uploaded into a separate object, so we can do file uploads separately
            model[key] = map(filesToUpload[key], 'name').join(', '); // set the model to the filename/s of the File/s so it shows in the UI
          }
        });
      }
    }
  }

  function fileUpload(fileFields) {
    var deferred = $q.defer();
    var promises = [];
    var filenames = {};

    angular.forEach(fileFields, function (field, key) {
      if (!isEmpty(field)) {
        filenames[key] = [];
        for (var i = 0; i < field.length; i++) {
          promises.push(
            Upload.upload({
              url: '/v1/files',
              file: field[i]
            }).success(function (data) {
              // eslint-disable-line no-loop-func
              filenames[key].push(data.file.split('?')[0].split('/').slice(-2).join('/')); // need to update the scenario.model_json with the <GUID>/<filename> of the uploaded file
            })
          );
        }
      }
    });

    $q.all(promises).then(function () {
      deferred.resolve(filenames);
    });

    return deferred.promise;
  }

  function setFileUploads(form) {
    var filesToUpload = {};

    var fileUploadFields = filter(form, function (item) {
      return item.type === 'fileupload';
    });

    if (!isEmpty(fileUploadFields)) {
      forEach(fileUploadFields, function (item) {
        filesToUpload[item.key] = [];
      });
    }

    return filesToUpload;
  }

  function updateModelWithFilePaths(fileFields, scenario) {
    angular.forEach(fileFields, function (files, fieldName) {
      scenario.model_json[fieldName] = files.join(', ');
    });
  }

  function getStaticUrl(path) {
    return CONFIG.STATIC_URL + path; // eslint-disable-line
  }
}

FileFactory.$inject = ['$q', '$resource', 'Upload'];
export default FileFactory;
