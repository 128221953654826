import { DataGrid, GridColumns, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { CrudFilters } from '@refinedev/core';
import { DeleteButton, EditButton, List, ShowButton, useDataGrid } from '@refinedev/mui';

import { Module } from '../../types';

const ModuleList = () => {
  const { dataGridProps, search } = useDataGrid<Module>({
    onSearch: search => {
      const filters: CrudFilters = [];

      filters.push({
        field: 'search',
        value: search,
        operator: 'eq'
      });

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'search',
          value: '',
          operator: 'eq'
        }
      ]
    }
  });

  const columns: GridColumns = [
    { field: 'display_name', headerName: 'Name', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: params => (
        <>
          <ShowButton recordItemId={params.row.id} hideText />
          <EditButton recordItemId={params.row.id} hideText />
          <DeleteButton recordItemId={params.row.id} hideText />
        </>
      ),
      sortable: false,
      flex: 1,
      maxWidth: 128
    }
  ];

  return (
    <List canCreate>
      <DataGrid
        {...dataGridProps}
        filterModel={undefined}
        columns={columns}
        components={{ Toolbar: CustomToolbar }}
        onFilterModelChange={({ quickFilterValues }) => {
          search(quickFilterValues?.join(' ') || '');
        }}
        autoHeight
      />
    </List>
  );
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default ModuleList;
