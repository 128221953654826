import { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useAutocomplete } from '@refinedev/mui';
import { Controller } from 'react-hook-form';

import { AutocompleteInput, TextFieldInput } from '../../../components';

export const NameInput = ({ required = true }) => {
  return (
    <TextFieldInput
      name="name"
      label="Name"
      rules={required ? { required: 'Package name is required' } : {}}
      fullWidth
      margin="normal"
    />
  );
};

export const DescriptionInput = () => {
  return <TextFieldInput name="description" label="Description" fullWidth margin="normal" />;
};

export const PackageTypeInput = ({ isLoading, val = '' }) => {
  const [packageTypeValue, setPackageTypeValue] = useState('');

  const { autocompleteProps } = useAutocomplete({
    resource: 'flex_package_types',
    fetchSize: 10000,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'name__icontains',
        operator: 'contains',
        value: searchText
      }
    ]
  });

  const { options } = autocompleteProps;

  useEffect(() => {
    if (val && !isLoading) {
      const option = options.find(element => element.id === val);
      if (option) {
        setPackageTypeValue(option);
      }
    }
  }, [isLoading, options, val]);

  return (
    <AutocompleteInput
      {...autocompleteProps}
      sx={{ mt: 0.5 }}
      name="package_type"
      rules={{ required: 'Package Type is required' }}
      defaultValue={null}
      required
      getOptionLabel={option => option.name || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={packageTypeValue}
      onChange={(event, newValue) => {
        setPackageTypeValue(newValue);
      }}
      TextFieldProps={{
        label: 'Package Type'
      }}
    />
  );
};

export const ProjectTemplateInput = () => {
  const { autocompleteProps } = useAutocomplete({
    resource: 'assessment_templates',
    fetchSize: 50,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'name__icontains',
        operator: 'contains',
        value: searchText
      }
    ]
  });

  return (
    <AutocompleteInput
      {...autocompleteProps}
      sx={{ mt: 0.5 }}
      name="project_template"
      rules={{ required: 'Project Template is required' }}
      defaultValue={null}
      required
      getOptionLabel={option => option.template_name || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      TextFieldProps={{
        label: 'Project Template'
      }}
    />
  );
};

export const CreditsInput = () => {
  return <TextFieldInput name="credits" label="Credits" fullWidth margin="normal" />;
};

export const PurposeInput = () => {
  return <TextFieldInput name="purpose" label="Purpose" fullWidth margin="normal" />;
};

export const VersionInput = () => {
  return <TextFieldInput name="version" label="Version" fullWidth margin="normal" />;
};

export const FeaturedCheckbox = ({ control, isLoading, val = false }) => {
  const [checkboxValue, setCheckboxValue] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setCheckboxValue(val);
    }
  }, [isLoading, val]);

  return (
    <FormControlLabel
      label="Featured"
      control={
        <Controller
          name="featured"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              onChange={e => {
                setCheckboxValue(e.target.checked);
                onChange(e.target.checked);
              }}
              checked={checkboxValue}
            />
          )}
        />
      }
    />
  );
};
