import { Dispatch, SetStateAction, useState } from 'react';

function useToggle(initialValue = false): [boolean, () => void, Dispatch<SetStateAction<boolean>>] {
  const [value, setValue] = useState(initialValue);

  const toggle = () => {
    setValue(value => !value);
  };

  return [value, toggle, setValue];
}

export default useToggle;
