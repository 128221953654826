import { useEffect, useRef } from 'react';
import { JSONEditor as SvelteJSONEditor } from 'svelte-jsoneditor/dist/jsoneditor.js';

type Content = { json: any; text: undefined } | { json: undefined; text: string };

const JSONEditor: React.FC<{
  value: string;
  onChange: (newContent: any) => void;
}> = props => {
  const { value, onChange } = props;
  const refContainer = useRef<any>(null);
  const refEditor = useRef<any>(null);
  const refContent = useRef<Content>({ text: undefined, json: value });

  useEffect(() => {
    // create editor
    refEditor.current = new SvelteJSONEditor({
      target: refContainer.current,
      props: {
        content: refContent.current
      }
    });

    return () => {
      // destroy editor
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  // update props
  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps({
        ...props,
        onChange: (newContent: Content) => {
          if (newContent.text) {
            try {
              onChange(JSON.parse(newContent.text));
            } catch (e) {
              console.error('Invalid JSON, value not saved');
            }
          } else {
            onChange(newContent.json);
          }
        }
      });
    }
  }, [props, onChange]);

  return <div className="svelte-jsoneditor-react" ref={refContainer}></div>;
};

export default JSONEditor;
