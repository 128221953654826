import { FormatListNumbered } from '@mui/icons-material';
import { useActive, useCommands } from '@remirror/react';

import ButtonBase from './ButtonBase';

const ToggleOrderedListButton = () => {
  const { toggleOrderedList, focus } = useCommands();
  const { orderedList } = useActive();

  const handleClick = () => {
    toggleOrderedList();
    focus();
  };

  return (
    <ButtonBase active={orderedList()} disabled={!toggleOrderedList.enabled()} onClick={handleClick}>
      <FormatListNumbered />
    </ButtonBase>
  );
};

export default ToggleOrderedListButton;
