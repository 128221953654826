import { useState } from 'react';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { useCreate, useDelete, useList, useOne, useResource } from '@refinedev/core';

import { ATTACKIQ_ID } from '@attackiq/constants';

const ScenarioTags = () => {
  const { id: scenarioId } = useResource();
  const [tagSearchValue, setTagSearchValue] = useState('');
  const { mutateAsync: createAsync } = useCreate();
  const { mutateAsync: deleteAsync } = useDelete();

  const {
    data: scenarioTags,
    isLoading,
    refetch: refetchScenarioTags
  } = useOne({
    resource: 'scenarios',
    id: scenarioId as string,
    meta: {
      tags: true // only to give it a different queryId than the one for the scenario
    },
    queryOptions: {
      select: (data: any) => data.data.tags
    }
  });

  const { data: tags } = useList({
    resource: 'tags',

    pagination: {
      pageSize: 10000
    }
  });

  const addNewScenarioTag = async (scenarioId: string, tagId: string) => {
    try {
      await createAsync({
        resource: 'scenario_tags',
        values: {
          company: ATTACKIQ_ID,
          scenario: scenarioId,
          tag: tagId
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      refetchScenarioTags();
    }
  };

  const removeScenarioTags = async (scenarioTagId: string) => {
    try {
      await deleteAsync({
        resource: 'scenario_tags',
        id: scenarioTagId
      });
    } catch (e) {
      console.error(e);
    } finally {
      refetchScenarioTags();
    }
  };

  const tagOptions = tags?.data?.filter(tag => !scenarioTags?.find(scenarioTag => scenarioTag.id === tag.id)) || [];

  if (isLoading) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1
        }}
      >
        {scenarioTags?.map((scenarioTag: any) => (
          <Chip
            onDelete={() => removeScenarioTags(scenarioTag.scenario_tag_id)}
            size="small"
            key={scenarioTag.id}
            label={`${scenarioTag.tag_set_name}: ${scenarioTag.display_name}`}
          />
        ))}
      </Box>
      <Autocomplete
        disablePortal
        options={tagOptions}
        getOptionLabel={(option: any) => `${option.tag_set_name}: ${option.display_name}`}
        value={null}
        inputValue={tagSearchValue}
        onInputChange={(_event, value) => setTagSearchValue(value)}
        renderInput={(params: any) => <TextField {...params} label="Add tag" />}
        onChange={(_event, newlySelectedTag) => {
          addNewScenarioTag(scenarioId as string, newlySelectedTag?.id as string);
          setTagSearchValue('');
        }}
      />
    </Box>
  );
};

export default ScenarioTags;
